body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer_row {
  background-color: #888;
  margin-top:20px;
  padding: 10px 0 10px 0;
}

.carousel-caption { top: 77%;}

.carousel-caption h1, h3 {
  color: #fff !important;
  background: url('./images/background2.jpg');
  box-shadow: 0px 0px 80px  #1065bc;
  padding: 15px;
  border-radius: 10px;
  font-size: 30px;
}


.carousel-caption { top: 40%;}


@media (max-width: 1000px) {
  .carousel-caption h1, h3 {
    font-size: 18px;
  }
}

@media (max-width: 700px) {
  .carousel-caption {
    font-size: 16px;
    top: 10%;
  }
}

@media (max-width:400px) {
  .carousel-caption h1, h3 {
    font-size: 14px;
  }
}

