html, body { min-height: 100%; min-height: 100vh; font-size: 18px; font-family: 'Roboto', sans-serif; 
}

h1 { font-size: 22px; }

a { 
  transition: color .25s ease;   
  color: rgb(107, 179, 228) !important; 
  transition: background-color, font-size .25s ease; 
  font-family: 'Roboto', sans-serif; 
}

.fullHeight {
  height: 100%;
  background-image: url('./images/background2.jpg');
  padding-bottom: 2%;
}

a:hover {
   text-decoration: none;
   color: #8fc7ec !important
}

.App {
  text-align: center;
}

#root .navbar-nav {
  text-align: center;
}

a.navbar-brand { 
  width: 66px; 
  height: 80px;
  border-radius: 5px;
  margin-left: 3%;
  background-image: url("./blue_logo_trans.png");
  background-size: contain;
  background-repeat: no-repeat;
  transition: box-shadow .25s;
  box-shadow: -2px -2px 15px rgba(73, 147, 197,1.0);
}

a.navbar-brand:hover {
  box-shadow: -2px -2px 15px rgba(226, 226, 226, 0.8);
}

.col-md-3 span { text-shadow: 0 0 5px  #062849; color: #fff;}

.card { width: 400px; }

h1, h2, h3, h4 {
  /* color: #1076BC; */
  color: #fff;
  text-shadow: 0 0 10px #062849;
}

.footer_row {
  font-size:12px;
  color: #1076BC;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
}

#root { min-height: 100%; padding-bottom:30px;   background: url("images/background2.jpg"); background: no-repeat; }

iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}


.cardstyles {
  border-radius:  5px;
  background-color : rgba(16,118,188,.8);
  width : 100%;
  padding : 20px;
  font-size : 20px;
  color: #fff;
}

.footband_holder {
  padding-top: 5%;
  min-width: 100%;
  background: url("images/background2.jpg");
  padding-bottom: 50px;
}

.footband_holder a:hover {
  text-decoration: none;

}

.youtube {
  position: relative;
  margin: 0 auto; 
  min-height:350px;
  max-width:100%;
  box-shadow: 0px 0px 15px #777;
  border-radius: 5px;
}

.youtubeholder {
  height: auto; 
  max-width: 75%; 
  margin-left: 13%;
}

@media(min-width: 1000px) {
  .footband_holder {
    max-height:423px;
    padding-bottom: 25px;
    margin: 0;
  }
}
@media(max-width: 1400px) {
  a.homeLink { display: none; }
}

@media(max-width: 1200px) {
  a { font-size: 14px;
     padding-right: 0.15em !important; 
     padding-left: 0.15em !important;
  }
}

@media(max-width: 992px) {
  .card-deck .cardstyles.card { width: 100%; flex: auto; margin-bottom: 10px;}

}

@media(max-width: 800px){
  .footband_holder {
    text-align: center;
  }

   ol.carousel-indicators li { display: none; }
  .footer_center { text-align: center!important; }
  .footer_center.third h3 { margin-top: 20px;}
  .footer_second { max-height: 200px; }
  .centerline { display: none; }
}

@media(min-width: 992px){

  .navbar-collapse {
    position: absolute;
    right: 5%;
  }

  .navbar-collapse a {
    margin: 0 20px 0 20px;
  }

}

@media(max-width: 800px) {
  .card { max-width: 500px; margin: 10px 0 10px 0;  }
  .card-body, .list-group-item { font-size:16px !important; }

}

.floatright { float: right; }

@media(max-width: 768px) {
  .floatright { float: none;}
  img.fiftyYears { max-height: 350px;}
  .footband_holder .container .row { display: block; }
  .footband_holder .container .row .col-sm { max-height: 250px;}
}

.slogan { 
  font-size: 10px; 
  text-align: left;
  position: relative;
  left: 65px;
  top: 50px;
  background-color: #1076bc;
  color: #fff;
  width:17.5em;
  padding-left: 4px;
  margin: 5px 0 5px 0;
  border-radius: 2px;
}

#root {
  background: rgb(25,24,24);
  background: linear-gradient(90deg, rgba(25,24,24,1) 0%, rgba(43,43,43,1) 20%, rgba(43,43,43,1) 80%, rgba(0,0,0,1) 100%);
}

.soow-padding {
  margin-bottom: 2.5%;
}

.centerline {
  position: absolute;
  background-color: #888;
  width: 1px;
  height: 22%;
  left: 50%;
  margin-top: 1%;
}

a.fblink {
  background-color: transparent;
  background-image: url("images/fb.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 65px;
  left: 10px;
  border-radius: 50%;
  border-color: transparent;
  border-style: solid !important;
  transition: border-color .25s;
  border-width:5px;
  box-shadow: 4px 6px 16px #555;
  transition: box-shadow .15s;
}

a.fblink:hover {
  cursor: pointer;  
  box-shadow: 4px 6px 16px #888;

}

.grecaptcha-badge{
	visibility: collapse !important;  
}


option:hover,
option:focus,
option:active,
option:visited,
option:checked  { color:#fff;  background: linear-gradient(#1076BC, #1076bc); }

.card-deck .cardstyles.card, .list-group .list-group-item { box-shadow: 4px 4px 13px rgba(10,10,10,1); }

.main_content {
  min-width: 100%;
  min-height: 60vh;
}



.topBlock, .lowBlock { 
  position: relative;
  background: rgb(12,79,144);
  background: linear-gradient(90deg, rgba(12,79,144,1) 0%, rgba(16,118,188,1) 51%, rgba(12,79,144,1) 100%);
  box-shadow: inset 0px 0px 5px rgb(0, 44, 94);
}

.midBlock {
  background: rgb(12,79,144);
  background: linear-gradient(90deg, rgba(12,79,144,1) 0%, rgba(16,118,188,1) 51%, rgba(12,79,144,1) 100%);
}

.oldphoto1, .oldphoto2 {
  background-image: url('./images/oldphoto1.JPG');
  background-repeat: no-repeat;
  background-size: cover;
}

.oldphoto2 { 
  background-image: url('./images/tb_background2.jpg');
  padding-bottom: 0px;
}

.lowBlock {  background-color: #1076BC;
}

.lowBlock { padding: 8% 20px 8% 20px; text-align: center; }

article h2 { margin-top: 16%; }

article { margin-bottom: 15%;  text-shadow: 0 0 2px #062849;}

.headernavbar { background-image: url('./images/background2.jpg');}

#basic-navbar-nav {
  background-color: black;
  box-shadow: 0 0 10px rgba(150,150,150,.4);
  border-radius: 8px;
}

img.fiftyYears { max-width: 300px; margin: 20px 0 20px 0; height: auto; }

.sticky-top { z-index: 99!important; }

img.modalthumb { 
  max-width: 90% !important;
  margin: 10px 0 5px 0;
  border-radius: 5px;
  box-shadow: 0px 0px 20px black;
}

.lowBlock .col-md-3 {
  margin-bottom: 20px;
}

td {
  color: #fff;
  width: 48%;
}

.dropdown-menu  {
  background: rgb(12,79,144);
  background: linear-gradient(90deg, rgba(12,79,144,1) 0%, rgba(16,118,188,1) 51%, rgba(12,79,144,1) 100%);  text-align: left;
}

a.dropdown-item {
  color: #fff !important;
  text-shadow: 0 0 2px #062849;
  transition: color .2s;
}

a.dropdown-item:hover {
  background-color: transparent;
  text-shadow: 0 0 20px #000;
  color: #000 !important;
}

button.digicontact {
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: #1065bc;
  padding: 15px;
  border-radius: 15px;
  border-color: #000;
  box-shadow: 0 0 10px #000;
  color: #fff !important;
  transition:  box-shadow .30s;
  z-index: 1000;

}

a.digicontact:hover {
  box-shadow: 0 0 15px #fff;
  cursor: pointer;
}

form.digiform input, form.digiform select {
  width: 60%;
  padding: 5px;
}

form.digiform .form-label {
  float: left;
  width: 30%;
}

button.digibutton {
  border-color: #000;
  border-radius: 5px;
  background-color: #ddd;
}

@media(max-width: 1200px) {
  a.digicontact {
    padding: 15px !important;
  }
}

@media(max-width: 992px) {

  a.dropdown-item { text-align: center; }
  a.dropdown-item:hover { color: #1065bc !important;}
  .dropdown-menu.show { background-color: rgba(20,20,20,0.9);}

}

.thumbholder img {  
  transition: box-shadow .25s;

}

.thumbholder img:hover {
  box-shadow: 0px 0px 15px rgba(200,200,200,.6);
}

.container .imageRow {
  border-radius: 10px;
  padding: 20px;
  background-color:#333;
  box-shadow: 0 0 5px #111;
}

.container {
  color: #fff;
}

img.bumpdown { margin-top: 30%;}
img.rotate_ninety {
  transform: rotate(90deg);
}

.header-highlight {
  background-color: #1065bc;
  padding: 10px;
  border-radius: 3.5px;
  text-align: center;
  background: linear-gradient(90deg, rgba(16,101,188, 50%) 0%, rgba(16,101,188, 100%) 50%,  rgba(16,101,188, 50%) 100%);
}

button.btn.btn-primary { background-color: #1065bc; }

.contactsuccessmsg {
  border: thin solid white;
  border-radius: 12px;
  vertical-align: center;
  padding: 10%;
  color: #fff;
  background-color: #1065bc;
  margin: 10% 0 10% 0;
  font-size: 1.5em;
}

h4.project_samples_title {
  min-height: 50px;
  font-size: 1.2em;
}

.fifty_year_holder {
  margin: auto;
  text-align: center;
}

.project_slider {
  background-color: rgba(0,0,0,.4);
  border-radius: 10px;
  padding: 20px 0 20px 0;
  margin: 0 5px 0 5px;
  overflow: hidden;
}

.project_slider img {
  border-radius: 10px;
  max-height: 400px;
  
}